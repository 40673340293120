<template>
  <div class="v-main-x">
    <div class="prmsv2">
      <div class="v-head">
        <div class="v-row">
          <!-- <div class="v-col-1">
            <b-form-checkbox
              v-model="value.selectedAll"
              name="item-selected-all"
              @change="changeAll()"
            />
          </div> -->
          <div class="v-col-2">
            <span class="v-file-name">Sản phẩm</span>
          </div>
          <div class="v-col-3">
            <span class="v-file-name">Tồn kho</span>
          </div>
          <div class="v-col-4">
            <span class="v-file-name">Số lượng đặt</span>
          </div>
          <div class="v-col-5">
            <span class="v-file-name">Đơn giá</span>
          </div>
          <div class="v-col-6">
            <span class="v-file-name">Giá giảm</span>
          </div>
          <div class="v-col-7">
            <span class="v-file-name">Thành tiền</span>
          </div>
          <div class="v-col-8">
            <!-- <span class="v-file-name">Thành tiền</span> -->
          </div>
        </div>
      </div>
      <div
        v-for="item, index in value"
        :key="index"
        class="v-item"
      >
        <div class="v-row v-item-head d-flex justify-content-between">
          <!-- <div class="v-col-1">
            <span class="v-check">
              <b-form-checkbox
                v-model="value.selected"
                :value="item.id"
                :name="`item-${index}`"
              />
            </span>
          </div> -->
          <div class="v-col-2-4">
            <img
              class="v-avatar"
              :src="item.avatar"
              alt=""
            >
            <span class="v-name-prod">{{ item.name }}</span>
          </div>
          <!-- <div class="v-col-10">
            <span
              class="v-btn-del"
              @click="removeItem(index)"
            ><feather-icon icon="Trash2Icon" /></span>
          </div> -->
        </div>
        <div
          v-for="itemChild, indexChild in item.children"
          :key="indexChild"
          class="v-row align-items-center"
        >
          <!-- <div class="v-col-1">
            &#160;
          </div> -->
          <div class="v-col-2">
            <span class="v-file-name">{{ itemChild.name }}</span>
            <p>Barcode: {{ itemChild.barcode || `---` }} </p>
          </div>
          <div class="v-col-3">
            <span class="v-file-name">{{ itemChild.stock || 0 }}</span>
          </div>
          <div class="v-col-4">
            <input
              v-model="value[index].children[indexChild].quantity"
              type="number"
              @blur="sumQuantity"
              @input="checkQt(index, indexChild)"
            >
          </div>
          <div class="v-col-5">
            <div class="v-input-mark">
              <input
                v-model.number="value[index].children[indexChild].cost_price"
                type="number"
                class="v-true"
                @blur="sumPrice"
                @input="checkPrice(index, indexChild)"
              >
              <input
                :value="unitFormatOriginal(value[index].children[indexChild].cost_price)"
                class="v-false"
                readonly
                tab-index="-1"
              >
            </div>
          </div>
          <div class="v-col-6">
            <div class="v-input-mark">
              <input
                v-model="value[index].children[indexChild].discount"
                type="number"
                class="v-true"
                @blur="sumPrice"
                @input="checkDc(index, indexChild)"
              >
              <input
                :value="unitFormatOriginal(value[index].children[indexChild].discount)"
                class="v-false"
                readonly
              >
            </div>
          </div>
          <div class="v-col-7">
            {{ unitFormatOriginal(totalPrice(itemChild.quantity, itemChild.cost_price, itemChild.discount)) }}
          </div>
          <div class="v-col-8">
            <span
              class="v-valid-status"
              @click="deleteVariant({id_product: item.id_product, index: index, id: itemChild.id, indexChild: indexChild})"
            >
              <feather-icon icon="XIcon" />
            </span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import general from '@/mixins/index'

export default {
  components: {
  },
  mixins: [general],
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      limitProductsOptions: [
        {
          value: null,
          text: 'Không giới hạn',
        },
        {
          value: 'limit',
          text: 'Giới hạn',
        },
      ],
    }
  },
  watch: {
  },
  methods: {
    deleteVariant(data = {}) {
      this.$emit('deleteVariant', data)
    },
    totalPrice(qty, cPrice, discount) {
      if (Number(qty) === 0) return 0
      return Number(qty) * (Number(cPrice) - Number(discount))
    },
    sumQuantity() {
      this.$emit('sumQuantity')
    },
    sumPrice() {
      this.$emit('sumPrice')
    },
    checkQt(index, indexChild) {
      const item = this.value[index].children[indexChild]
      item.quantity = Number(item.quantity)
      if (item.quantity < 0) {
        item.quantity = 0
      }
    },

    checkPrice(index, indexChild) {
      const item = this.value[index].children[indexChild]
      item.cost_price = Number(item.cost_price)
      if (item.cost_price < 0) {
        item.cost_price = 0
      }
    },

    checkDc(index, indexChild) {
      const item = this.value[index].children[indexChild]
      item.discount = Number(item.discount)
      if (item.discount < 0) {
        item.discount = 0
      }
      if (item.discount > item.cost_price) {
        item.discount = item.cost_price
      }
    },
  },
}
</script>

<style lang="scss">
  .v-main-x{
    width: 100%;
    @media (max-width: 767px) {
      overflow-x: auto;
      .prmsv2{
        width: 800px;
      }
    }

    .prmsv2{
      position: relative;
      overflow-x: auto;
      input, select{
        height: 26px;
        width: 100%;
        max-width: 120px;
        border: 1px solid #ddd;
      }
      input:focus,
      input:focus-visible,
      input:active,
      select:focus,
      select:active{
        border: 1px solid #ddd;
        outline: none;
      }
      .v-row{
        display: flex;
        // .v-col-1{
        //   width: 10px;
        // }
        .v-col-2{
          width: 20%;
        }
        .v-col-3{
          width: 10%;
          padding-left: 5px;
        }
        .v-col-4{
          width: 16%;
          padding-left: 5px;
        }
        .v-col-5{
          width: 16%;
          padding-left: 5px;
        }
        .v-col-6{
          width: 16%;
          padding-left: 5px;
        }
        .v-col-7{
          width: 17%;
          padding-left: 5px;
        }
        .v-col-8{
          width: 5%;
        }
        // .v-col-9{
        //   min-width: 130px;
        // }
        // .v-col-10{
        //   min-width: 70px;
        // }
        .v-col-2-4{
          min-width: 380px;
          display: flex;
          align-items: center;
        }
        .v-col-4-5{
          min-width: 182px;
          padding-right: 10px;
        }
        .v-col-6-7{
          min-width: 130px;
          padding-right: 10px;
        }
      }
      .v-file-name{
        display: block;
      }
      .v-head{
        background: #eee;
        padding: 10px;
        /* position: sticky; */
        /* top: 62px; */
        z-index: 12;
        margin-top: 8px;
        width: 100%;
        @media (max-width: 1350px) {
          font-size: 12px;
        }
      }
      .v-item{
        margin-top: 10px;
        border-bottom: 1px solid #ddd;
        width: 100%;

        .v-item-head{
          padding: 5px 10px;
          background: transparent;
          position: relative;
          align-items: center;
          display: flex;
        }
        .v-row{
          padding: 10px;
          &:hover {
            background: rgba(0,0,0,.075);
          }
        }
      }
      .v-avatar{
        width: 35px;
        height: 35px;
        object-fit: cover;
        margin-right: 10px;
      }
      .v-name-prod{
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .v-block-price{
        display:flex;
        align-items: center;
      }
      .v-input-group{
        max-width: 39%;
        .v-input{
          width: 100%;
        }
      }
      .v-mid-text{
        font-size: 11px;
        font-style: italic;
        margin-left: 5px;
        margin-right: 5px;
      }

      .v-item-discount{
        position: relative;
        .v-input-label{
          position: absolute;
          left: 7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 11px;
        }
        .v-input{
          padding-left: 20px;
        }
      }

      .v-item-percent{
        position: relative;
        .v-input-label{
          position: absolute;
          right: 7px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 11px;
        }
        .v-input{
          padding-right: 50px;
        }
      }
      .v-limit-grpup{
        display: flex;
        align-items: center;
        padding-right: 5px;
        .v-limit-grpup-item{
          max-width: 100%;
        }
        select{
          font-size: 11px;
        }
        input{
          display: none;
          margin-left: -2px;
        }
        &.limit{
          select{
            max-width: 70px;
          }
          input{
            display: block;
            max-width: calc(100% - 90px);
          }
        }
      }
      .v-btn-del{
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        margin: 0 auto;
        align-content: center;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #fff;
        font-size: 12px;
        &:hover{
          background: tomato;
          color: #fff;
        }
      }
      .v-valid-status{
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        margin: 0 auto;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: red;
      }
      .v-valid-warning{
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        margin: 0 auto;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: #ff9f43;
      }
      .error{
        color: red;
      }
      .warning{
        color: #ff9f43;
      }
    }
  }
</style>
