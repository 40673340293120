<!-- eslint-disable no-param-reassign -->
<!-- eslint-disable array-callback-return -->
<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col
          cols="12"
          xl="9"
        >
          <b-row class="match-height">
            <!-- supplier -->
            <b-col md="6">
              <b-card :title="$t('Supplier')">
                <validation-provider
                  #default="{ errors }"
                  name="Supplier"
                  rules="required"
                >
                  <v-select
                    v-model="supplierSelected"
                    :options="supplierOptions"
                    label="name"
                    :reduce="x => x"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="w-100 mb-1"
                  >
                    <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Add New Supplier') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                        @lick="hanldeLoadSupplier"
                      >
                        <feather-icon
                          icon="RefreshCwIcon"
                          size="16"
                        />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <span v-if="supplierSelected">{{ supplierSelected.address }}</span>
                <div
                  v-if="supplierSelected"
                  class="d-flex justify-content-between mt-1"
                >
                  <span>Còn nợ: <strong>{{ unitFormatOriginal(supplierDebt) || '--' }}</strong></span>
                  <router-link :to="`/supplier/edit/${supplierSelected.id}`">
                    Xem chi tiết
                  </router-link>
                </div>
              </b-card>
            </b-col>
            <!-- warehouse -->
            <b-col md="6">
              <b-card :title="$t('Warehouse')">
                <validation-provider
                  #default="{ errors }"
                  name="Warehouse"
                  rules="required"
                >
                  <v-select
                    v-model="model.storage.id_number"
                    :options="warehouseOptions"
                    label="name"
                    :reduce="x => x.id_number"
                    input-id="invoice-data-client"
                    :clearable="false"
                    class="mb-1"
                  >
                    <!-- <template #list-header>
                      <a
                        class="add-new-client-header d-flex align-items-center my-50"
                        tag="li"
                        href="/supplier/add"
                        target="_blank"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Add New Warehouse') }}</span>
                      </a>
                      <li
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon icon="RefreshCwIcon" size="16" />
                        <span class="align-middle ml-50">{{ $t('Refresh Data') }}</span>
                      </li>
                    </template> -->
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <span>{{ model.storage.address }}, {{ model.storage.wards_prefix }} {{ model.storage.wards_name }}, {{ model.storage.districts_name }}, {{ model.storage.province_name }}</span>
                <div class="d-flex justify-content-between mt-1">
                  <span>{{ model.storage.phone }}</span>
                  <!-- <router-link to="#">Xem chi tiết</router-link> -->
                </div>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                :title="$t('Product')"
                class="mih-100"
              >
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    :placeholder="$t('Search product')"
                    @click="modalShow = !modalShow"
                  />
                  <b-input-group-append is-text>
                    <span
                      class="cursor-pointer"
                      @click="modalShow = !modalShow"
                    >
                      {{ $t('Search') }}
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <!-- <div class="mt-1">
                  <vue-good-table
                    :columns="columnsDone"
                    :rows="rowsDone"
                    class="custom-good-table-row-head"
                    style-class="vgt-table"
                    :group-options="{
                      enabled: true,
                      headerPosition: 'top'
                    }"
                    @on-rows-change="selectionChanged"
                  >
                    <template
                      slot="table-header-row"
                      slot-scope="props"
                    >
                      <b-avatar
                        :key="props.row.img"
                        :src="props.row.img"
                        variant="light-info"
                        rounded
                        class="p-avatar"
                      />
                      <span class="my-fancy-class">
                        {{ props.row.label }}
                      </span>
                    </template>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <div
                        v-if="props.column.field === 'name'"
                        class="avatar-name mx-auto"
                      >
                        <div class="name-child">
                          {{ props.row.name }}
                        </div>
                        <div class="sku">
                          <strong>SKU:&nbsp;</strong>{{ props.row.sku }}
                        </div>
                      </div>
                      <div
                        v-else-if="props.column.field === 'stock'"
                        class="count-w mx-auto"
                      >
                        {{ props.row.stock }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'quantity'"
                        class="count-w"
                      >
                        <b-form-input v-model="rowsDone[props.row.originalIndex]" @input="sumQuantity()" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'cost_price'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.cost_price" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'discount'"
                        class="count-w"
                      >
                        <b-form-input v-model="props.row.discount" />
                      </div>
                      <div
                        v-else-if="props.column.field === 'total'"
                        class="text-end"
                      >
                        {{ unitFormatOriginal(props.row.quantity * props.row.cost_price) }}
                      </div>
                      <div
                        v-else-if="props.column.field === 'close'"
                        class="d-flex text-center"
                      >
                        <b-button
                          variant="flat-danger"
                          class="text-danger btn-icon rounded-circle"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </template>
                  </vue-good-table>
                </div> -->
                <items
                  v-model="rowsDone"
                  @sumQuantity="sumQuantity"
                  @sumPrice="sumPrice"
                  @deleteVariant="deleteVariant"
                />
              </b-card>
              <b-card
                title="Ghi Chú"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="model.note"
                  :placeholder="$t('Enter note')"
                  rows="3"
                  max-rows="6"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          xl="3"
        >
          <div class="position-sticky top">
            <b-card>
              <b-form-group
                :label="$t('Ngày nhận hàng dự kiến') + `:`"
                label-cols-md="12"
              >
                <flat-pickr
                  v-model="model.expected_date"
                  class="form-control"
                  :config="{
                    minDate: 'today',
                    altFormat: 'd-m-Y',
                    dateFormat: 'Y-m-d',
                    altInput: true,
                  }"
                  placeholder="Choose a date"
                />
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Date"
                  rules="required"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-card>
            <b-card title="Chi phí mua hàng">
              <div class="d-flex justify-content-between">
                <span>Tổng số lượng đặt</span>
                <span>{{ totalQty }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>Tổng tiền hàng</span>
                <span>{{ unitFormatOriginal(totalPrice) }}</span>
              </div>
              <div class="d-flex justify-content-between my-1">
                <span>VAT</span>
                <!-- <b-form-input v-model="vat" /> -->
                <b-input-group class="w-50">
                  <b-form-input
                    v-model="vat"
                    style="height:30px"
                  />
                  <b-input-group-append
                    is-text
                    style="height:30px"
                  >
                    <span
                      class="cursor-pointer"
                      @click="modalShow = !modalShow"
                    >
                      %
                    </span>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div
                v-if="vatVal"
                class="d-flex justify-content-between my-1"
              >
                <span>Tổng VAT</span>
                <span>{{ unitFormatOriginal(vatVal) }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <strong class="text-primary">Tiền trả NCC</strong>
                <strong>{{ unitFormatOriginal(totalPriceNcc) }}</strong>
              </div>
              <!-- <div class="d-flex justify-content-between mt-1">
                <strong>Còn nợ</strong>
                <span>0 đ</span>
              </div> -->
              <div class="text-center mt-2 w-100">
                <b-button
                  variant="primary"
                  type="submit"
                  class="w-100"
                  @click.prevent="validationForm"
                >
                  {{ $t('Stock Input') }}
                </b-button>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <!-- modal add selected -->
    <b-modal
      id="modal-list"
      v-model="modalShow"
      size="lg"
      scrollable
      title="Tìm kiếm sản phẩm"
    >
      <div class="mih-100">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchData"
            @keyup.enter="getDataSearch"
          />
          <b-input-group-append is-text>
            <span
              class="cursor-pointer"
              @click="getDataSearch"
            >
              {{ $t('Search') }}
            </span>
          </b-input-group-append>
        </b-input-group>
        <div class="mt-1">
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >Số tồn kho</b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in items"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray cursor-pointer"
                  >
                    <b-form-checkbox
                      :id="`parent-${item.id}`"
                      v-model="item.selected"
                      :indeterminate="item.indeterminate"
                      @change="handleSelectAll(item)"
                    />
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.warehouse"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray cursor-pointer"
                    >
                      <div class="d-flex pl-4">
                        <b-form-checkbox
                          :id="`${val.id}`"
                          v-model="val.selected"
                          :class="val.done ? 'd-none' : ''"
                          @change="handleSelectItem(item, val, idx)"
                        />
                        <div class="pl-1">
                          <span class="d-block">
                            {{ ` ${val.name_attribute[0] ? val.name_attribute[0].value : ''}${val.name_attribute[1] ? ` / ${val.name_attribute[1].value}` : ''}${val.name_attribute[2] ? ` / ${val.name_attribute[2].value}` : ''}` }}
                          </span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">{{ val.stock || '--' }} tồn kho</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
          >
            {{ arrAttribute.length }} mục đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="generalSelectDone"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShow = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- modal show item be selected -->
    <b-modal
      id="modal-show-item"
      v-model="modalShowItem"
      size="lg"
      scrollable
      title="Sản phẩm đã chọn"
    >
      <div class="mih-100">
        <div>
          <b-row class="bg-gray">
            <b-col cols="9">
              Sản phẩm
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >
              Số tồn kho
            </b-col>
          </b-row>
          <div>
            <ul>
              <li
                v-for="(item, index) in selectedArr"
                :key="index"
                class="mt-1"
              >
                <div>
                  <label
                    :for="`parent-${item.id}`"
                    class="d-flex align-items-center hover-bg-gray"
                  >
                    <!-- <b-form-checkbox :id="`parent-${item.id}`" v-model="item.selected" :indeterminate="item.indeterminate" @change="handleSelectAll(item)"/> -->
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar
                        square
                        variant="light-dark"
                        :src="item.avatar"
                      />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                    <div class="pl-2">
                      <feather-icon
                        class="cursor-pointer"
                        size="18"
                        icon="XIcon"
                      />
                    </div>
                  </label>
                </div>
                <ul>
                  <li
                    v-for="(val, idx) in item.warehouse"
                    :key="idx"
                    class="mt-1"
                  >
                    <label
                      :for="`${val.id}`"
                      class="d-flex justify-content-between hover-bg-gray"
                    >
                      <div class="d-flex pl-4">
                        <!-- <b-form-checkbox :id="`${val.id}`" v-model="val.selected" @change="handleSelectItem(val)" /> -->
                        <div class="pl-1">
                          <span class="d-block">
                            {{ ` ${val.name_attribute[0] ? val.name_attribute[0].value : ''}${val.name_attribute[1] ? ` / ${val.name_attribute[1].value}` : ''}${val.name_attribute[2] ? ` / ${val.name_attribute[2].value}` : ''}` }}
                          </span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">
                        <feather-icon
                          class="cursor-pointer"
                          size="18"
                          icon="XIcon"
                        />
                      </div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p
            class="float-left mb-0 "
          >
            {{ arrAttribute.length }} mục đã chọn
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="modalShowItem = false"
          >
            Hoàn tất chọn
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="modalShowItem = false"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
/* eslint-disable-next-line no-param-reassign */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  // BFormCheckbox,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  // BCardHeader,
  BAvatar,
  // BPagination,
  // BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
// import { VueGoodTable } from 'vue-good-table'
// import Treeselect from '@riophae/vue-treeselect'
// import vrcode from '@ispa.io/vrcode'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Items from './Items.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormCheckbox,
    BCard,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    // BCardHeader,
    BAvatar,
    // VueGoodTable,
    // BPagination,
    // BFormSelect,
    BFormCheckbox,
    vSelect,
    // Treeselect,
    // vrcode,
    flatPickr,
    Items,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      avatarText,
      required,
      supplierOptions: [],
      warehouseOptions: [],
      model: {
        expected_date: null,
        storage: {},
        note: '',
      },
      supplierSelected: null,
      supplierDebt: null,
      vat: 0,
      vatVal: 0,
      pageLength: 10,
      // setting col row list be selected
      columns: [
        {
          label: 'Tên sản phẩm',
          field: 'name',
          tdClass: 'text-left',
        },
        {
          label: 'Tồn kho',
          field: 'inventory',
          tdClass: 'text-center',
        },
      ],
      // setting col row list select
      rowsDone: [],
      modalShow: false,
      modalShowItem: false,
      items: [],
      pSelected: [],
      cSelected: [],
      // list tạm thời đã chọn
      selectedArr: [],

      // array attribute + parent
      arrAttribute: [],
      arrProduct: [],

      // search in modal
      searchData: '',
      arrTotal: [],
      totalQty: 0,
      totalPrice: 0,
      totalPriceNcc: 0,
    }
  },
  watch: {
    cSelected(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
    vat() {
      this.sumPrice()
    },
    supplierSelected() {
      this.loadSupplierDetail()
    },
  },
  created() {
    this.getStorage()
    this.loadSupplier()
    this.loadProducts()
  },
  methods: {
    sumQuantity() {
      let t = 0
      // eslint-disable-next-line array-callback-return
      this.rowsDone.map(x => {
        // eslint-disable-next-line array-callback-return
        x.children.map(y => {
          t += Number(y.quantity)
        })
      })
      this.totalQty = t
      this.sumPrice()
    },
    sumPrice() {
      let t = 0
      let vat = 0
      // eslint-disable-next-line array-callback-return
      this.rowsDone.map(x => {
        // eslint-disable-next-line array-callback-return
        x.children.map(y => {
          if (Number(y.quantity) === 0) t += 0
          else t += Number(y.quantity) * (Number(y.cost_price) - Number(y.discount))
        })
      })
      if (this.vat !== 0) vat += ((t * this.vat) / 100)
      this.totalPrice = t
      this.vatVal = vat
      this.totalPriceNcc = vat + t
    },
    deleteVariant(data) {
      this.rowsDone[data.index].children.splice(data.indexChild, 1)
      if (this.rowsDone[data.index].children.length === 0) {
        this.rowsDone.splice(data.index, 1)
        this.arrProduct.splice(data.index, 1)
      }
      const indexAttr = this.arrAttribute.findIndex(x => x.id === data.id)
      this.arrAttribute[indexAttr].done = false
    },
    getDataSearch() {
      if (this.searchData) {
        this.loadProducts(`&name=${this.searchData}`)
      } else this.loadProducts()
    },
    handleSelectItem(data, val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id_product === val.product_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].warehouse.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d > 0 && d < this.items[parent].warehouse.length) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = true
      }
      if (d === this.items[parent].warehouse.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
      if (val.selected) {
        // this.arrAttribute.push(val)
        if (!this.arrAttribute.find(e => e.id === val.id)) {
          this.arrAttribute.push(val)
        }
      } else {
        this.arrAttribute = this.arrAttribute.filter(e => e.id !== val.id)
      }

      if (this.items[parent].selected || this.items[parent].indeterminate) {
        if (!this.arrProduct.find(e => e.id_product === data.id_product)) {
          this.arrProduct.push(data)
        }
      } else {
        this.arrProduct = this.arrProduct.filter(e => e.id_product !== data.id_product)
      }
    },
    handleSelectAll(val) {
      if (val.selected) {
        const parent = this.items.findIndex(x => x.id_product === val.id_product)
        this.items[parent].indeterminate = false
        if (!this.arrProduct.find(e => e.id_product === val.id_product)) {
          this.arrProduct.push(val)
        }
        // eslint-disable-next-line array-callback-return
        val.warehouse.map(x => {
        // eslint-disable-next-line no-param-reassign
          x.selected = val.selected
          if (!this.arrAttribute.find(e => e.id === x.id)) {
            this.arrAttribute.push(x)
          }
        })
      } else {
        this.arrProduct = this.arrProduct.filter(e => e.id_product !== val.id_product)
        // eslint-disable-next-line array-callback-return
        val.warehouse.map(x => {
        // eslint-disable-next-line no-param-reassign
          x.selected = val.selected
        })
        this.arrAttribute = this.arrAttribute.filter(e => e.product_id !== val.id_product)
      }
    },
    selectionChanged(e) {
      this.selectedArr = e.selectedRows
    },
    // click show modal
    generalSelectShow() {
      const temp = []
      // eslint-disable-next-line array-callback-return
      this.arrProduct.map((item, index) => {
        if (item.selected) {
          // if (!this.selectedArr.find(x => x.id_product === item.id_product))
          temp.push(item)
        } else {
          temp.push({
            ...item,
            warehouse: [],
          })
          // eslint-disable-next-line array-callback-return
          this.arrAttribute.map(x => {
            if (x.product_id === item.id_product) {
              temp[index].warehouse.push(x)
            }
          })
        }
      })
      this.selectedArr = temp
      this.modalShowItem = !this.modalShowItem
    },
    generalSelectDone() {
      // const temp = []
      // eslint-disable-next-line array-callback-return
      this.arrProduct.map((item, index) => {
        const indexProd = this.rowsDone.findIndex(x => x.id_product === item.id_product)
        if (indexProd === -1) {
          if (item.selected) {
            this.arrProduct[index].selected = false
            this.rowsDone.push({
              id_product: item.id_product,
              name: item.name,
              avatar: item.avatar,
              children: [],
            })
            // eslint-disable-next-line array-callback-return
            this.arrAttribute.map(x => {
              if (x.product_id === item.id_product) {
                // eslint-disable-next-line no-param-reassign
                x.done = true // eslint-disable-next-line no-param-reassign
                x.selected = false
                this.rowsDone[index].children.push({
                  id: x.id,
                  product_id: x.product_id,
                  cost_price: x.cost_price,
                  discount: 0,
                  price: x.price,
                  quantity: 0,
                  stock: x.storages_warehouse[0] ? x.storages_warehouse[0].quantity_stock : 0,
                  name: ` ${x.name_attribute[0] ? x.name_attribute[0].value : ''}${x.name_attribute[1] ? ` / ${x.name_attribute[1].value}` : ''}${x.name_attribute[2] ? ` / ${x.name_attribute[2].value}` : ''}`,
                  total: 0,
                  sku: x.sku,
                  barcode: x.barcode,
                })
              }
            })
          } else {
            this.arrProduct[index].indeterminate = false
            this.rowsDone.push({
              mode: 'span',
              id_product: item.id_product,
              name: item.name,
              avatar: item.avatar,
              children: [],
            })
            // eslint-disable-next-line array-callback-return
            this.arrAttribute.map(x => {
              if (x.product_id === item.id_product) {
                // eslint-disable-next-line no-param-reassign
                x.done = true // eslint-disable-next-line no-param-reassign
                x.selected = false
                this.rowsDone[index].children.push({
                  id: x.id,
                  product_id: x.product_id,
                  cost_price: x.cost_price,
                  discount: 0,
                  price: x.price,
                  quantity: 0,
                  stock: x.storages_warehouse[0] ? x.storages_warehouse[0].quantity_stock : 0,
                  name: ` ${x.name_attribute[0] ? x.name_attribute[0].value : ''}${x.name_attribute[1] ? ` / ${x.name_attribute[1].value}` : ''}${x.name_attribute[2] ? ` / ${x.name_attribute[2].value}` : ''}`,
                  total: 0,
                  sku: x.sku,
                  barcode: x.barcode,
                })
              }
            })
          }
        } else {
          this.arrProduct[index].indeterminate = false
          this.arrProduct[index].selected = false
          // eslint-disable-next-line array-callback-return
          this.arrAttribute.map(x => {
            if (!this.rowsDone[indexProd].children.find(y => y.id === x.id)) {
              if (x.product_id === item.id_product) {
                // sau khi general thì chuyen check prod thanh false
                // eslint-disable-next-line no-param-reassign
                x.done = true // eslint-disable-next-line no-param-reassign
                x.selected = false
                this.rowsDone[indexProd].children.push({
                  id: x.id,
                  product_id: x.product_id,
                  cost_price: x.cost_price,
                  discount: 0,
                  price: x.price,
                  quantity: 0,
                  stock: x.storages_warehouse[0] ? x.storages_warehouse[0].quantity_stock : 0,
                  name: ` ${x.name_attribute[0] ? x.name_attribute[0].value : ''}${x.name_attribute[1] ? ` / ${x.name_attribute[1].value}` : ''}${x.name_attribute[2] ? ` / ${x.name_attribute[2].value}` : ''}`,
                  total: 0,
                  sku: x.sku,
                  barcode: x.barcode,
                })
              }
            }
          })
        }
      })
      // this.rowsDone = []
      // this.rowsDone = temp
      this.modalShow = !this.modalShow
    },
    hanldeLoadSupplier() {
      this.loadSupplier()
    },
    async loadSupplierDetail() {
      const year = new Date().getFullYear()
      const params = {
        dashboard: true,
        date_from: `${year}-01-01`,
        date_to: `${year}-12-31`,
      }
      const res = await this.mixGet(`/supplier/${this.supplierSelected.id}`, params)
      if (res.status) {
        this.supplierDebt = res.data.dashboard.total_debt
      }
    },
    async getStorage() {
      const res = await this.mixGet('/storages', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.warehouseOptions = res.data.items
          this.model.storage = res.data.items.find(x => x.is_default === 1)
        }
      }
    },
    async loadSupplier() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/suppliers?site_id=${siteId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadProducts(data = '') {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}${data}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // this.items = res.data.data.items
            const temp = []
            // eslint-disable-next-line array-callback-return
            res.data.data.items.map((item, index) => {
              const find = this.arrProduct.find(x => x.id_product === item.id_product)
              if (!find) {
                temp.push({
                  ...item,
                  warehouse: [],
                  selected: false,
                  indeterminate: false,
                })
                // eslint-disable-next-line array-callback-return
                item.warehouse.map(x => {
                  temp[index].warehouse.push({
                    ...x,
                    name_attribute: JSON.parse(x.name_attribute),
                    selected: false,
                    done: false,
                  })
                })
              } else {
                temp.push({
                  ...item,
                  warehouse: [],
                  selected: find.selected,
                  indeterminate: find.indeterminate,
                })
                if (temp[index].selected) {
                  // eslint-disable-next-line array-callback-return
                  item.warehouse.map(x => {
                    temp[index].warehouse.push({
                      ...x,
                      name_attribute: JSON.parse(x.name_attribute),
                      selected: true,
                      done: false,
                    })
                  })
                }
                // eslint-disable-next-line array-callback-return
                item.warehouse.map(x => {
                  const findAttri = this.arrAttribute.find(y => y.id === x.id)
                  if (findAttri) {
                    temp[index].warehouse.push({
                      ...x,
                      name_attribute: JSON.parse(x.name_attribute),
                      selected: findAttri.selected,
                      done: findAttri.done,
                    })
                  } else {
                    temp[index].warehouse.push({
                      ...x,
                      name_attribute: JSON.parse(x.name_attribute),
                      selected: false,
                      done: false,
                    })
                  }
                })
              }
            })
            this.items = (temp)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        const end = true
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              supplier_id: this.supplierSelected.id_number,
              storage_id: this.model.storage.id_number,
              status: 1,
              expected_date: this.model.expected_date,
              note: this.model.note,
              vat: Number(this.vat),
              total_price: this.totalPrice,
              total_quantity: this.totalQty,
              warehouses: [],
            }
            if (this.rowsDone && this.rowsDone.length > 0) {
              const temp = []
              this.rowsDone.map(x => {
                x.children.map(y => {
                  if (y.quantity > 0) {
                    temp.push({
                      id: y.id,
                      quantity: Number(y.quantity),
                      price: y.cost_price,
                      discount_price: y.discount,
                    })
                  }
                  return end
                })
                return end
              })
              params.warehouses = JSON.stringify(temp)
            }
            if (params.warehouses === '[]') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('Invalid order quantity'),
                },
              })
              return end
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/request_goods`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create success',
                  },
                })
                this.$router.push('/purchasing-orders/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
        return end
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;
  cursor: pointer;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.custom-good-table-row-head {
  .custom-select{
    width: 100%;
  }
  .b-avatar {
    margin-right: 8px;
  }
  table.vgt-table {
    font-size: 14px !important;
  }
  thead th {
    vertical-align: middle;
    text-align: center;
    // padding-right: 0.75em;
  }
  tbody th.vgt-checkbox-col {
    padding: 0 .75em 0 2.75em;
    border-right: none;
  }
  td {
    vertical-align: middle !important;
  }
  .p-avatar {
    margin-left: 16px;
  }
  .avatar-name {
    min-width: 200px !important;
    padding-left: 4.25em;

    .name-child {
      color: #333;
      font-weight: 500;
    }
  }
  .count-w {
    max-width: 100px;
  }
  .miw-150 {
    min-width: 150px;
  }
  .vgt-table th.vgt-row-header{
    // border-top: 3px solid #ebe9f1;
    border-bottom: none;
    font-size: 14px;
    thead th {
      vertical-align: middle;
      text-align: center;
      padding-right: 0.75em;
      padding-left: 0;
      white-space: nowrap;
    }
    td {
      vertical-align: middle;
    }

    .custom-th-class {
      text-align: end;
    }
  }
}
#modal-show-item {
  table.vgt-table {
    thead {
      display: none;
    }
    tbody td.text-left {
      padding-left: 5.25em;
      border-bottom: none;
    }
    tbody td.text-center {
      border-bottom: none;
    }
  }
}
</style>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin-top: 1rem;
}
ul li {
  list-style: none;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 0;
}
.hover-bg-gray{
  padding: .5rem 0;
}
.hover-bg-gray:hover{
  padding: .5rem 0;
  background: #f6f6f6;
}
label {
  font-size: 14px;
}
.mih-100 {
  min-height: 350px;
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
